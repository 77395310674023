import React from 'react'
import './Footer.css';

const Footer = () => {
    return (
        
        <div className="footer">
            <h3>Mohammed Wasif Uddin</h3>
            <h6>Copyright 2021</h6>
        </div>
    )
}

export default Footer
